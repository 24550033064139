import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";


import {GET_EDITFONT,GET_FONTDETAILLIST }  from '../../actions'; 

class Edit extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const FontId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
			fontname:'',
			fontfamilyname:'',
			fonturl:'',
			font_file: [],
			status:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			c_font_id: FontId,
			Loading:false
		};
		this.props.getFontDetailList(FontId);
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		//console.log(this.state.c_company_id+"-test");

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Font Edit');
    }

        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					c_font_id : formPayload.c_font_id,
					font_name    : formPayload.fontname,
					fonturl    : formPayload.fonturl,
					font_family_name    : formPayload.fontfamilyname,
					selectedFile : formPayload.selectedFile,
					status       : status,
				};
				//console.log(postObject);
				this.props.getEditFont(qs.stringify(postObject));
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {fontname,fontfamilyname,selectedFile,fonturl} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		if (!fontname) {
			errors++;
			$('.errorfont_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfont_name').html('');
		}
		
		if (!fontfamilyname) {
			errors++;
			$('.errorfontfamilyname').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfontfamilyname').html('');
		}

		/*if (!fonturl) {
			errors++;
			$('.errorfonturl').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfonturl').html('');
		}*/


		/*if (selectedFile === null || selectedFile === '') {
		$('.errorfile').html('<span class="errorspan">Please choose the font file</span>');
		errors++;
		}else if(selectedFile){
	    var filePath = selectedFile.name;
	    var allowedExtensions =  
                    /(\.ttf)$/i; 
        if(!allowedExtensions.exec(filePath)){
        	errors++;
		    $('.errorfile').html('<span class="errorspan">Please choose only ttf format</span>');
        }else{
            $('.errorfile').html('');
        } 
        }else{             
		$('.errorfile').html('');
		}*/

		if(errors>0) {
		
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    componentWillReceiveProps(Props){

   	if(Props.fontdetails !== this.props.fontdetails){
    		if(Object.keys(Props.fontdetails).length > 0){
				this.setState({Loading:false});

					if(Props.fontdetails[0].status === "success"){

						const formdata = Props.fontdetails[0].fontlist;
						if(formdata.tf_status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}
						this.setState({fontname:formdata.tf_name});							
						this.setState({fonturl: formdata.tf_import_url});
						this.setState({fontfamilyname: formdata.tf_font_family});
					}
    		}
    	}
    	if(Props.fontedit !== this.props.fontedit){
    		if(Object.keys(Props.fontedit).length > 0){
				if(Props.fontedit[0].status === "success"){
    					this.setState({ Loading: false });

    			const formpayload = Props.fontedit[0].fontlist;
				if(formpayload.tf_status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}
				//this.setState({c_company_id: formpayload.c_company_id});
				this.setState({fontname:formpayload.tf_name});							
						this.setState({fonturl: formpayload.tf_import_url});
						this.setState({fontfamilyname: formpayload.tf_font_family});
               	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.fontedit[0].message+'</h3></div>');


    					setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/fonts'));
						}
						.bind(this),
						3000
						);
    		}else{
					this.setState({ Loading: false });
					$('.success_message').html('<div class="status_sucess"><h3>'+ Props.fontedit[0].message+'</h3></div>');
    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
			}
			}
    	}
		
    }
	
  render() {
  	  const {selectedOption} = this.state;
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="fontlist" />  

	
	<div className="content">	
		<div className="content-wrapper">
				  <div className="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Font Details</h4>
			</div>
			 <form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>Font Name:</label>
				    <input type="text" name="fontname" onChange={this.handleInputChange} className="form-control" value={this.state.fontname} autoComplete="off" />
					<div className="errorfont_name"></div>
				</div>
			
               <div className="form-group" style={{display:"none"}}>
					<label>Font:</label>
				 <input type="file" onChange={this.onFileChange} multiple /> 
                <div className="errorfile"></div> 
                </div>
				
				<div className="form-group">
					<label>Exact Family Name:</label>
				    <input type="text" name="fontfamilyname" onChange={this.handleInputChange} className="form-control" value={this.state.fontfamilyname} autoComplete="off" />
					<div className="errorfontfamilyname"></div>
				</div>
				<div className="form-group">
					<label>Font Import url:</label>
					<textarea className="form-control" name="fonturl" onChange={this.handleInputChange}  value={this.state.fonturl} id="" rows="5"  autoComplete="off" />
					<div className="errorfonturl"></div>
				</div>
				
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
			</div>				

			</div>	

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
   fontdetails: state.fontdetails,
   fontedit: state.fontedit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFontDetailList: (FontId) =>{
       dispatch({ type: GET_FONTDETAILLIST,FontId });
    },
     getEditFont: (formPayload) =>{
       dispatch({ type: GET_EDITFONT, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Edit));