import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';

import { GET_DASHBOARDLIST  } from '../../actions';

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      dashboardlist:''
    };
    
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
    var postobject = {
    activePage: 1,
    search_all: '',
    admin_id: admin_id,
    loggedas: loggedas
    };
    this.props.getDashboardList(qs.stringify(postobject));
    }

   
    componentDidMount() {
   }

   componentWillReceiveProps(Props){

    if (Object.keys(Props.dashboardlist[0]).length > 0) {
      if (Props.dashboardlist[0]["status"] === "success") {
        this.setState({ 
          totalRecord : Props.dashboardlist[0].records_count_product, 
          totalRecordUsers : Props.dashboardlist[0].records_count_users,
          totalRecordCompanies : Props.dashboardlist[0].records_count_companies,
          totalRecordcategory : Props.dashboardlist[0].records_count_category,
          totalRecordpayment : Props.dashboardlist[0].records_count_payment,

          totalRecordDoctor : Props.dashboardlist[0].records_count_doctor,
          totalRecordCategories : Props.dashboardlist[0].records_count_categories,
          totalRecordProductCateogory : Props.dashboardlist[0].records_count_product_category,
          });
      }
    }   
  }


  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="dashboard" />
      
      <div className="content"> 
        <div className="content-wrapper">
              <div class="content-wrapper-before"></div>
              <div className="car-listing-wrap dashboard-wrap leaa-admin-head card">

                  <div className="title">
                    <h3>Dashboard</h3>
                  </div>

                  <div className="form-row first">

                        {localStorage.getItem('admin_id') === '1' ?  
                        <div className="form-left">
                        <div className="card pull-up border-top-info border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge">{this.state.totalRecordUsers}</h4>
                            </div>
                              <a href="user" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Users <i className="ft-users float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                       </a>
                    </div>
                          </div> : ''}

                        {localStorage.getItem('admin_id') === '1' ?  <div className="form-right">
                            <div className="card pull-up border-top-info-red border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge-red card-title-red ">{this.state.totalRecordCompanies}</h4>
                            </div>
                              <a href="/doctors" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Company<i className="ft-briefcase float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                       </a>
                    </div>
                          </div>: ''}
                    </div> 

                    <div className="form-row ">
            					<div className="form-left">
            					
                        <div className="card pull-up border-top-info-yellow border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge-yellow card-title-yellow">{this.state.totalRecordcategory}{/* <span className="badge badge-pill badge-info float-right m-0">5+</span>*/}</h4>
                            </div>
                              <a href="category" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Specialist<i className="ft-server float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </a>
                        </div>   
            					</div>
                     
            					 <div className="form-right">
                         <div className="card pull-up border-top-info-purple border-top-3 rounded-0">
                            <div className="card-header">
                            <h4 className="card-title card-badge-purple card-title-purple">{this.state.totalRecordDoctor}{/* <span className="badge badge-pill badge-info float-right m-0">5+</span>*/}</h4>
                             </div>
                              <a href="product" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Provider <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                            </a>
                          </div>
            					  </div> 
            				</div>

                    {/* -------------- */}
                    <div className="form-row ">
            					<div className="form-left">
            					
                        <div className="card pull-up border-top-info-blue border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge card-title-blue">{this.state.totalRecord}{/* <span className="badge badge-pill badge-info float-right m-0">5+</span>*/}</h4>
                            </div>
                              <a href="category" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Products<i className="ft-server float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </a>
                        </div>   
            					</div>
                     
            					 <div className="form-right">
                         <div className="card pull-up border-top-info-black border-top-3 rounded-0">
                            <div className="card-header">
                            <h4 className="card-title card-badge card-title-black">{this.state.totalRecordCategories}{/* <span className="badge badge-pill badge-info float-right m-0">5+</span>*/}</h4>
                             </div>
                              <a href="product" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Category <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                            </a>
                          </div>
            					  </div> 
            				</div>
                    <div className="form-row ">
            					<div className="form-left">
                        <div className="card pull-up border-top-info-orange border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge card-title-orange">{this.state.totalRecordProductCateogory}{/* <span className="badge badge-pill badge-info float-right m-0">5+</span>*/}</h4>
                            </div>
                              <a href="category" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Product Category<i className="ft-server float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </a>
                        </div>   
            					</div>
                    </div>

                    {/* -------------------- */}
                      
                </div>
          </div>  
      </div>
      
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    dashboardlist: state.dashboardlist,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardList: (formVal) =>{
      dispatch({ type: GET_DASHBOARDLIST, formVal});
   },
   
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));