import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import $ from 'jquery';

import { apiUrl} from'../Config/Config';
import axios from 'axios';

import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';

import { GET_COMPANYLIST  } from '../../actions';
import parse from 'html-react-parser';
class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      companylist:'',
      activePage:1
    };
    // this.props.getCompanyList();
    
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
      
    }

    
   handleInputChange(event) {
    const {name, value} = event.target;      
    this.setState({
      [name]: value
    });
    
  }
 
  handleFormSubmit = () => {             
    this.setState({Loading:true});
    var qs = require('qs');
    const formPayload = this.state;
      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");
    var postobject = {             
      activePage   : 1,
      search_all : formPayload.search_all,
      admin_id: admin_id,
      loggedas: loggedas         
    };
    axios.post(apiUrl+"company/list",qs.stringify(postobject)).then(res => {     
      if(res.data.status === 'success'){
          this.setState({  companylist: res.data, totalRecord: res.data.records_count}, function() {
            this.companylist();
          });
      }else{
        this.setState({  companylist: "", totalRecord: ""}, function() {
          this.companylist();
        });
      }
    }); 
 }    

   componentDidMount() {
    $('.model-popup').hide();
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
      var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: admin_id,
      loggedas: loggedas
      };  
   //  this.props.getCategoryList(qs.stringify(postobject))
   axios.post(apiUrl+"company/list",qs.stringify(postobject)).then(res => {
     
     if(res.data.status === 'success'){
         this.setState({  companylist: res.data, totalRecord: res.data.records_count}, function() {
           this.companylist();
         });
     }
   }); 
   }


   componentWillReceiveProps(Props){
//     if (Object.keys(Props.companylist).length > 0) {
//       if (Props.companylist[0]["status"] === "success") {
//         this.setState({
//           companylist: Props.companylist[0]["companylist"],
//           totalRecord:Props.companylist[0]["records_count"],
//         });
//       }
//     }     
   }


  handlePageChange(pageNumber) {
     this.setState({activePage: pageNumber});
     var qs = require('qs');
     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
    //  this.props.getCategoryList(qs.stringify(postobject))
    axios.post(apiUrl+"company/list",qs.stringify(postobject)).then(res => {      
      if(res.data.status === 'success'){
          this.setState({  companylist: res.data, totalRecord: res.data.records_count}, function() {
            this.companylist();
          });
      }
    }); 
  }

  copycompany(id){
    this.setState({companyid : id})

    $('.confirm-action-message').html('Are you sure you want to clone ?');	
    $('.confirm-action').addClass('show');	
    
   }


   deletecompany(id){
    this.setState({companyid : id})
    $('.confirm-action-delete-message').html('Are you sure delete the company?');	
    $('.confirm-action-delete').addClass('show');	
   }


  companylist() {

    var companylist = this.state.companylist;
console.log(companylist,'companylistcompanylist')
    if (companylist != "undefined" && companylist != null) {
      if (companylist.status == "success") {
        if (Object.keys(companylist).length > 0) {
          const companylistDetails = companylist.companylist.map(
            (companylist, Index) => {
              let sno = Index+1;
              if(companylist.admin_status == 'Yes'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              var domainlist = companylist.domains;
              let domvalu ='';
              if(domainlist!== undefined ){
                   domvalu  = domainlist.map((doms) => {
                   return doms.domain_name;
                  }).join(',');
              }else{
                domvalu = 'N/A';
              }
             
              var admin_contact = ((companylist.admin_contact == null)||(companylist.admin_contact == '')) ?  companylist.admin_contact = 'N/A' : companylist.admin_contact;
              return (
                <tr key={companylist.admin_id}>
                  <td>{sno}</td>
                  <td>
                  {companylist.admin_company_name}
                  </td>                 
                  <td>{companylist.admin_email_address}</td>
                  <td>{admin_contact}</td>
                  <td>{companylist.admin_username}</td>
                  <td>{companylist.admin_org_password}</td>
                  <td>{parse(domvalu.split(',').join("<br />"))}</td>
                  <td>{status}</td>
                  <td className="actiongroup">
                    <Link to={{ pathname: '/company-edit/'+companylist.admin_id}} className="" title="Edit">  <i className="ft-edit-3" aria-hidden="true"></i></Link>
                    <Link onClick={this.copycompany.bind(this,companylist.admin_id)}  className="" title="Clone Company">  <i className="ft-copy" aria-hidden="true"></i></Link>
                    <Link onClick={this.deletecompany.bind(this,companylist.admin_id)}  className="" title="Copy Category">  <i className="fa fa-trash" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return companylistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="6" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  cloneConfirm =(confirmstatus)=>{
    if(confirmstatus == 'yes'){
			$('.confirm-action').removeClass('show');
		}else{
			$('.confirm-action').removeClass('show');	
		}
  }

  deleteConfirm =(confirmstatus,company_id)=>{
    if(confirmstatus == 'yes'){
			$('.confirm-action-delete').removeClass('show');
      var qs = require('qs');       
      var postObject = { 
        column: 'admin_id',         
        value: company_id,   
        activePage:this.state.activePage,  
        admin_id:localStorage.getItem("admin_id")
      };
      axios.post(apiUrl+"company/delete",qs.stringify(postObject)).then(res => {
        if(res.data.status === 'success'){             
          this.setState({  companylist: res.data, totalRecord: res.data.records_count}, function() {
            this.companylist();
          });
        }
      });  
		}else{
			$('.confirm-action-delete').removeClass('show');	
		}
  }

  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="companylist" />    
  <div className="content"> 
    <div className="content-wrapper">
          <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">

        <div className="listing-header"> 
          <div className="title">
            <h3>Companies</h3>
          </div> 
          <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
          </form>
                </div>
          <div className="add_button">  
              <a className="add" href='/company-add'>Add</a> 
          </div>
        </div>
       

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Company</th>               
                <th>Email</th>
                <th>Contact</th>
                <th>Username</th>
                <th>Password</th>
                <th>Domains</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.companylist()}</tbody>
          </Table>
        </div>
          <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
  
                      
      </div>  
    </div>  
  </div>
             <div className="confirm-action">
								<p className="confirm-action-message"></p>
                <Link  to={{ pathname: '/company-clone/'+this.state.companyid}} className="btn" title="Clone Company">Yes </Link>
                <a href="javascript://" onClick={this.cloneConfirm.bind(this, "No")} className="btn">No</a>
								<span class="close-support" onClick={this.cloneConfirm.bind(this, "No")}  >X</span>
						 </div>

             <div className="confirm-action-delete">
								<p className="confirm-action-delete-message"></p>
                
                
								<a href="javascript://" onClick={this.deleteConfirm.bind(this, "yes",this.state.companyid)} className="btn">Yes</a>
								<a href="javascript://" onClick={this.deleteConfirm.bind(this, "No")} className="btn">No</a>
								<span class="close-support" onClick={this.deleteConfirm.bind(this, "No")}  >X</span>
						 </div>
      
    </div>
    );
  }
}
export default withRouter(List);