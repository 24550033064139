import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import Select from 'react-select';
import Multiselect from 'multiselect-react-dropdown';
import { lang } from '../Helpers/lang';

import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
  }  from 'react-places-autocomplete';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
	
	
const initialState = {
			name:'',
			email:'',
			phoneno:'',
			status:'',
			description:'',
			selectedDomain:'',
			domainlist:[],
			specialist:[],
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			domain_id:'',
			specialist_id:'',
			dont_distrub_checked:false,
			image:'',
			image_name: '',
			image_preview: '',address:'',
			locationlist: [{id: '', name: 'Select Your Location'}],
			locationselectedList:[],
			avalibility:''
		};
		
class Add extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state =  { ...initialState }
		
	    this.handleInputChange = this.handleInputChange.bind(this);
       	this.handleChange = this.handleChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);


    }
	handleInputChange(event) {

		const {name, value} = event.target;      
		this.setState({
			[name]: value
		  });
		if(name == 'dont_distrub'){
			this.setState({dont_distrub_checked: !this.state.dont_distrub_checked})
		}
	  }
    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		  this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file
		  });
		};
		reader.readAsDataURL(file);
	}

	componentDidMount() {
		
		document.title = PageTitle('Add Doctor');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}

		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'doctor/getdomainlist?admin_id='+admin_id)
		
		.then(res => {
			if(res.data.status == 'success'){
			
				this.setState({
					domainlist: [{value: '', label: 'Select Your Domain'}].concat(res.data.domainlist)
				})
			
			}else{
			
			}
		});
		axios.get(apiUrl+'doctor/getspecialist?admin_id='+admin_id)		
		.then(res => {
			if(res.data.status == 'success'){
			
				this.setState({
					specialist: [{value: '', label: 'Select Your Specialist'}].concat(res.data.specialist)
				})
			
			}else{
			
			}
		});			
		axios.get(apiUrl+'doctor/getlocation?admin_id='+admin_id)		
		.then(res => {
			if(res.data.status == 'success'){			
				this.setState({
					locationlist: [].concat(res.data.location)
				})			
			}else{
			
			}
		});	
    }

	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
			    var status = '';				
				const config = {
					headers: {
						'content-type': 'multipart/form-data'
					}
				}
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}				
				var dontditrub = 0;
				if(this.state.dont_distrub_checked == true){
					var dontditrub = 1;
				}
			
				const locationselectedList = formPayload.locationselectedList;
	var elements=[];
	var elements1=[];
	let locList  = ''
	if(locationselectedList!=='' && typeof locationselectedList!==undefined && locationselectedList!==null) {
		locList = locationselectedList.map((number) =>
			elements.push(number.id)
			
		);
		locList = locationselectedList.map((number) =>
			elements1.push(number.name.replace(/[,]/g,' '))
		);
	}

	const locationselectedLists=elements.toString();
	const locationselectedLists1=elements1.toString();

				var postObject = {
			        admin_id : localStorage.getItem("admin_id"),
					domain_id : formPayload.domain_id,
					name     : formPayload.name,
					graduation: formPayload.graduation,
					description: formPayload.description,
					specialist    : formPayload.specialist_id,
					experience_yr     : formPayload.experience,
					email        : formPayload.email,
					phoneno      : formPayload.phoneno,
					location: formPayload.location,
					consultant_fee: formPayload.fee,
					dont_distrub: dontditrub,
					status       :  status,
					image : formPayload.image,
					locationselectedid:locationselectedLists,
					locationselectedname:locationselectedLists1,
					avalibility:formPayload.avalibility
				};
				let formData = new FormData();
				for(let k in postObject) {
					formData.append(k, postObject[k]);
				}
				axios.post(apiUrl+"doctor/add",formData,config).then(res => {
					if(res.data.status === "success"){	
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						scrollToTop();
								setTimeout(
								function() {
									$('.success_message').html(this.props.history.push('/doctors'));
								}
								.bind(this),
								5000
								);
					}else{
						if(res.data.status === "erroremail"){

							$('.erroremail').html('<span class="errorspan">'+res.data.message+'</span>');
								scrollToTop();
								   setTimeout(
								   function() {
									   $('.erroremail').html('');
								   }
								   .bind(this),
								   5000
								   );
						   }else if(res.data.status === "errorphone"){
	   
							$('.errorphoneno').html('<span class="errorspan">'+res.data.message+'</span>');
								scrollToTop();
								   setTimeout(
								   function() {
									   $('.errorphoneno').html('');
								   }
								   .bind(this),
								   5000
								   );
						   }else if(res.data.status === "errorusername"){
	   
							$('.errorusername').html('<span class="errorspan">'+res.data.message+'</span>');
								scrollToTop();
								   setTimeout(
								   function() {
									   $('.errorusername').html('');
								   }
								   .bind(this),
								   10000
								   );
						   }else{
							   $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
							   scrollToTop();
							   setTimeout(
							   function() {
								   $('.success_message').html('');
							   }
							   .bind(this),
							   3000
							   );
						   }
						
					}
					this.setState({Loading:false});
				});
			}
	}

	validateForm() {
		const {name,email,phoneno,domain_id,specialist,location,fee,experience,graduation,avalibility} = this.state;
		let errors = 0;
		if (!name) {
			errors++;
			$('.errorname').html('<span class="errorspan">Please fill the field</span>');
		}else if(name){
			$('.errorname').html('');
		}

		if(!avalibility){
			errors++;
			$('.erroravalibility').html('<span class="errorspan">Please fill the field</span>');
		}else if(avalibility){
			$('.erroravalibility').html('');
		}

		if (!domain_id) {
			errors++;
			$('.errorassign_domain').html('<span class="errorspan">Please select the field</span>');
		}else if(domain_id){
			$('.errorassign_domain').html('');
		}

		if (!specialist) {
			errors++;
			$('.errorspecialist').html('<span class="errorspan">Please select specialist</span>');
		}else if(specialist){
			$('.errorspecialist').html('');
		}

		if (!graduation) {
			errors++;
			$('.errorgraduation').html('<span class="errorspan">Please fill the field</span>');
		}else if(graduation){
			$('.errorgraduation').html('');
		}
		if (!email) {
	    	errors++;			
			$('.erroremail').html('<span class="errorspan">Please fill the field</span>');
		}else if(email){
			var reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		    // var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
		    if (reg.test(email) == false) 
	        {
				errors++;				
	            $('.erroremail').html('<span class="errorspan">Please enter valid email address</span>');
	        }else{
	        	$('.erroremail').html('');
	        }
		}
		// if (!phoneno) {
		// 	errors++;
		// 	$('.errorphoneno').html('<span class="errorspan">Please fill the field</span>');
		// }else if(phoneno){
		// 	$('.errorphoneno').html('');
		// }

		if (!phoneno) {
			errors++;
			$('.errorphoneno').html('<span class="errorspan">Phone number is required</span>');
		  }else if(phoneno){	
			var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
			if (pattern.test(phoneno)) {
			$('.errorphoneno').html('');
			}else if(indiapattern.test(phoneno)){
			$('.errorphoneno').html('');
			}else{
				errors++;
				$('.errorphoneno').html('<span class="errorspan">Please enter valid phone number</span>');
			}
		}	



		if (!location) {
			errors++;
			$('.errorlocation').html('<span class="errorspan">Please fill the field</span>');
		}else if(location){
			$('.errorlocation').html('');
		}

		if (!fee) {
			errors++;
			$('.errorfee').html('<span class="errorspan">Please fill the field</span>');
		}else if(fee){
			$('.errorfee').html('');
		}

		if (!experience) {
			errors++;
			$('.errorexperience').html('<span class="errorspan">Please fill the field</span>');
		}else if(experience){
			$('.errorexperience').html('');
		}

		


		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }
	handleClickSelecet = (event) => {
		const selectedIndex = event.target.options.selectedIndex;
		var key = event.target.options[selectedIndex].getAttribute('data-key');

		this.setState({
				domain_id: key,
				
		})


	}

	handlespecialist = (event) => {
		const selectedIndex = event.target.options.selectedIndex;
		var key = event.target.options[selectedIndex].getAttribute('data-key');

		this.setState({
			specialist_id: key,
				
		})
	}
	  
	
	handleChangeaddress = location => {
		this.setState({ location });
	  };
	 
	  handleSelect = location => {

		geocodeByAddress(location)
		  .then(results => getLatLng(results[0]))
		  .then(latLng => console.log('Success', latLng))
		  .catch(error => console.error('Error', error));
		  this.setState({ location });
	  };

	onSelect = selectedList=>{		
		this.setState({locationselectedList:selectedList});		
	}
	
	onRemove = selectedList =>{
		this.setState({locationselectedList:selectedList});	
	}


  render() {

  	const {selectedOption,domainlist, specialist} = this.state;
	  var current = this;


    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="doctors" />  

	
	<div className="content">	
		<div className="content-wrapper">
		        <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">

			<div className="success_message"></div>
			<div className="title">
				<h4>Add Doctor</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
		<div className="form-row">
				<div className="form-left">
					<div className="form-group">
						<label>Doctor Name<span class="required" style={{ color: "red" }} > * </span>:</label>
						<input type="text" name="name" onChange={this.handleInputChange} className="form-control" value={this.state.name} />
						<div className="errorname"></div>
					</div>
					
					<div className="form-group">
						<label>Phone number<span class="required" style={{ color: "red" }} > * </span>:</label>
						<input type="text" name="phoneno" onChange={this.handleInputChange} className="form-control" value={this.state.phoneno} />
						<div className="errorphoneno"></div>
					</div>
					<div className="form-group">					
							<label>Specialist <span class="required" style={{ color: "red" }} > * </span>: </label>
							<select  onChange={current.handlespecialist}  value={specialist.cat_name}>                    
							{this.state.specialist.map((specialist) => <option key={specialist.value} data-key={specialist.value} value={specialist.value} >{specialist.label}</option>)}
							</select>	
							<div className="errorassign_domain"></div>
						</div>
					<div className="form-group">
							<label>Years of experience<span class="required" style={{ color: "red" }} > * </span>:</label>
							<input type="text" name="experience" onChange={this.handleInputChange} className="form-control" value={this.state.experience} autoComplete="off"/>
							<div className="errorexperience"></div>
					</div>

					
				   <div className="form-group">					
						<label>About Us:</label>
							<textarea type="text" name="description" onChange={this.handleInputChange} className="form-control" value={this.state.description}  />
						<div className="errordescription"></div>
				   </div>
				   <div className="form-group">					
						<label>Availability<span class="required" style={{ color: "red" }} > * </span>:</label>
						<input type="text" name="avalibility" onChange={this.handleInputChange} className="form-control" value={this.state.avalibility} autoComplete="off"/>
							<div className="erroravalibility"></div>
				   </div>

				   	<div className="form-group">
							<input onChange={this.handleInputChange} type="checkbox"  name="dont_distrub" checked={this.state.dont_distrub_checked}/>
							<label> Don't distrub </label>
					</div>
					<div className="form-group">
						<label>Icon:</label>
						<div className="choose-file">
						{this.state.isHidden && <div className="image_success"></div>}
						<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
						</div>
						{/* {preview} */}
						{/* {loader_image_preview?<a href="javascript:void(0);">X</a>:''} */}
						<div className="errorloaderimage"></div>  
					</div> 
				</div>
				<div className="form-right">
					  <div className="form-group">
							<label>Email<span class="required" style={{ color: "red" }} > * </span>:</label>
								<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} autoComplete="off" />
							<div className="erroremail"></div>
						</div>
						<div className="form-group">
							<label>Graduation<span class="required" style={{ color: "red" }} > * </span>:</label>
							<input type="text" name="graduation" onChange={this.handleInputChange} className="form-control" value={this.state.graduation} autoComplete="off"/>
							<div className="errorgraduation"></div>
					    </div>

						<div className="form-group">					
							<label>Assign Domain<span class="required" style={{ color: "red" }} > * </span>: </label>
							<select  onChange={current.handleClickSelecet}  value={domainlist.domain_name}>                    
							{this.state.domainlist.map((domainlist) => <option key={domainlist.value} data-key={domainlist.value} value={domainlist.value} >{domainlist.label}</option>)}
							</select>	
							<div className="errorassign_domain"></div>
						</div>
						
						<div className="form-group">
							<label>Consultant Fee($)<span class="required" style={{ color: "red" }} > * </span>:</label>
							<input type="text" name="fee" onChange={this.handleInputChange} className="form-control" value={this.state.fee} autoComplete="off"/>
							<div className="errorfee"></div>
					    </div>

						{/* <div className="form-group">
							<label>Location:</label>
							<input type="text" name="location" onChange={this.handleInputChange} className="form-control" value={this.state.location} autoComplete="off"/>
							<div className="errorlocation"></div>
					    </div> */}
						<div className="form-group">
						<label>Location<span class="required" style={{ color: "red" }} > * </span>:</label>
							<PlacesAutocomplete	value={this.state.location}	onChange={this.handleChangeaddress}	onSelect={this.handleSelect}			>
								{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
								<div>
									<input  type="text" name="location" className="form-control" value={this.state.location}	
									{...getInputProps({
										placeholder: 'Search Places ...',
										className: 'location-search-input',
									})}
									/>
									<div className="autocomplete-dropdown-container">
									{loading && <div>Loading...</div>}
									{suggestions.map(suggestion => {
										const className = suggestion.active
										? 'suggestion-item--active'
										: 'suggestion-item';
										// inline style for demonstration purpose
										const style = suggestion.active
										? { backgroundColor: '#fafafa', cursor: 'pointer' }
										: { backgroundColor: '#ffffff', cursor: 'pointer' };
										return (
										<div
											{...getSuggestionItemProps(suggestion, {
											className,
											style,
											})}
										>
											<span>{suggestion.description}</span>
										</div>
										);
									})}
									</div>
								</div>
								)}
							</PlacesAutocomplete>
							<div className="errorlocation"></div>
					    </div>

						<div className="form-group">
							<label>Preferred Location:</label>
							<Multiselect
							options={this.state.locationlist} // Options to display in the dropdown
							//  selectedValues={this.state.selectedValue} 
							 // Preselected value to persist in dropdown
							onSelect={this.onSelect} // Function will trigger on select event
							onRemove={this.onRemove} // Function will trigger on remove event
							displayValue="name" // Property name to display in the dropdown options
							/>
					    </div>

						<div className="default-font">
							<div className="form-group">					
								<label>Status:</label>
									<Select 
									value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
										options={lang.common.status_option} 
										onChange={this.handleChange}
										/>
							</div>
						</div>
					</div>
				</div>

			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true ?
                                        <span>Loading</span>:<span>Submit</span> 
                                        }
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default withRouter(Add);