import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";


import {GET_ADDCOMPANY }  from '../../actions'; 

class Add extends Component {	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			admin_company_name:'',			
			admin_fname:'',
			admin_username:'',
			admin_contact:'',
			admin_email_address:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			Loading:false,
			domain_count:'1'
		};
		
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Company Add');
    }


        handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					admin_company_name: formPayload.admin_company_name,					
					admin_username:formPayload.admin_username,
					admin_contact:formPayload.admin_contact,
					admin_email_address:formPayload.admin_email_address,
					admin_status:status,
					domain_count:formPayload.domain_count
				};
				this.props.getAddCompany(qs.stringify(postObject));
			}
	}

	validateForm() {
		const {admin_company_name,admin_fname,admin_username,admin_email_address,admin_contact,domain_count} = this.state;
		let errors = {};
      	let formIsValid = true;

		if (!admin_company_name) {
			formIsValid = false;
			$('.erroradmin_company_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(admin_company_name){
			$('.erroradmin_company_name').html('');
		}
		
		if (!admin_username) {
			formIsValid = false;
			$('.erroradmin_username').html('<span class="errorspan">Please fill the field</span>');
		}else if(admin_username){
			$('.erroradmin_username').html('');
		}		
		

		// if (!admin_contact) {
		// 	formIsValid = false;
		// 	$('.erroradmin_contact').html('<span class="errorspan">Please fill the field</span>');
		// }else if(admin_contact){
		// 	$('.erroradmin_contact').html('');
		// }

		
		if (!admin_contact) {
			formIsValid = false;
			$('.erroradmin_contact').html('<span class="errorspan">Please fill the field</span>');
		  }else if(admin_contact){	
			 var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			 var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
			    if (pattern.test(admin_contact)) {
				$('.erroradmin_contact').html('');
				}else if(indiapattern.test(admin_contact)){
				$('.erroradmin_contact').html('');
				}else{
					formIsValid = false;
					 $('.erroradmin_contact').html('<span class="errorspan">Please enter valid contact number</span>');
				   }
		}	

		if (!domain_count) {
			formIsValid = false;
			$('.errordomain_count').html('<span class="errorspan">Please fill the field</span>');
		}else if(domain_count){
			$('.errordomain_count').html('');
		}


		

		if (!admin_email_address) {
			formIsValid = false;
			$('.erroradmin_email_address').html('<span class="errorspan">Please fill the field</span>');
		}else if(admin_email_address){
			$('.erroradmin_email_address').html('');
		}



		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(Props){

   	if(Props.companyadd !== this.props.companyadd){
    		if(Object.keys(Props.companyadd).length > 0){
				this.setState({Loading:false});

					if(Props.companyadd[0].status === "success"){

						$('.success_message').html('<div class="status_sucess"><h3>'+ Props.companyadd[0].message+'</h3></div>');
						this.setState({
							admin_company_name:'',			
							admin_fname:'',
							admin_username:'',
							admin_contact:'',
							admin_email_address:'',
							selectedvalue: '',
							domain_count:''
						});
						setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/company'));
						}
						.bind(this),
						3000
						);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+ Props.companyadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}
    }
	
  render() {
  	  const {selectedOption} = this.state;
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="companylist" />  

	
	<div className="content">	
		<div className="content-wrapper">
				  <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Company</h4>				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Company Name<span class="required" style={{ color: "red" }} > * </span>:</label>
				    <input type="text" name="admin_company_name" onChange={this.handleInputChange} className="form-control" value={this.state.admin_company_name} />
					<div className="erroradmin_company_name"></div>
				</div>
				
			
				<div className="form-group">					
					<label>Contact<span class="required" style={{ color: "red" }} > * </span>:</label>
						<input type="text" name="admin_contact" onChange={this.handleInputChange} className="form-control" value={this.state.admin_contact} />
					<div className="erroradmin_contact"></div>
				</div>
				<div className="form-group">					
					<label>Domain Count<span class="required" style={{ color: "red" }} > * </span>:</label>
						<input type="number" name="domain_count" onChange={this.handleInputChange} className="form-control" value={this.state.domain_count} min-value='1' />
					<div className="errordomain_count"></div>
				</div>
				
				
			</div>
			<div className="form-right">	
			
				<div className="form-group">
					<label>User Name<span class="required" style={{ color: "red" }} > * </span>:</label>
				    <input type="text" name="admin_username" onChange={this.handleInputChange} className="form-control" value={this.state.admin_username} />
					<div className="erroradmin_username"></div>
				</div>
				<div className="form-group">
					<label>Email<span class="required" style={{ color: "red" }} > * </span>:</label>
						<input type="text" name="admin_email_address" onChange={this.handleInputChange} className="form-control" value={this.state.admin_email_address} />
					<div className="erroradmin_email_address"></div>
				</div>
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
					<div className=""></div>
				</div>
			</div>	
			</div>		

			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
									<span className="load-data"></span> 
									}Submit
			   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
  	companyadd: state.companyadd
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	 getAddCompany: (formPayload) =>{
       dispatch({ type: GET_ADDCOMPANY, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Add));